import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class cDataService {
  // File Manager Variables Start
  gitDetails = new BehaviorSubject<any>("");
  oGetFolders = new BehaviorSubject<any>("");
  GetFolders = new BehaviorSubject<any>("");
  selectBulkFolder = new BehaviorSubject<any>("");
  maintainBulkTree=new BehaviorSubject<any>("");
  oGetDocFolders = new BehaviorSubject<any>("");
  oGetRecentDoc=new BehaviorSubject<any>("");
  populateGlobalSeacrhField = new BehaviorSubject<any>("");
  updateHeaderLogo=new BehaviorSubject<any>("");
  sendSelectedQueery = new BehaviorSubject<any>("");
  selectedFiles = new BehaviorSubject<any>('');
  refresh = new BehaviorSubject<boolean>(false);
  refreshDoc = new BehaviorSubject<boolean>(false);
  clickLogo = new BehaviorSubject<boolean>(false);
  bFetchDeletedFilesCheck=new BehaviorSubject<boolean>(false);
  sCurrentFolderName = new BehaviorSubject<string>('')
  sCurrentDocFolderName = new BehaviorSubject<string>('')
  addingNewSubFolder = new BehaviorSubject<string>('')
  addingNewDocSubFolder = new BehaviorSubject<string>('')
  bRenameLoader=new BehaviorSubject<boolean>(false);
  bDisplyDropdown = new BehaviorSubject<boolean>(false);
  bDisplaySettingDropdown = new BehaviorSubject <boolean>(false); 
  bShowHeaderSearch = new BehaviorSubject <boolean>(false); 
  resetSearch = new BehaviorSubject <boolean>(false);
  lAutoCompleteList=new BehaviorSubject<any>([]);
  bDisplayTableButtons = new BehaviorSubject<boolean>(false);
  bDisplayDocEllipses = new BehaviorSubject<boolean>(false);
  bTableAttributes = new BehaviorSubject<any>("");
  sSelecetedTableName = new BehaviorSubject<any>("");
  bDisplayConfirmationPopupAfterChanges = new BehaviorSubject<boolean>(false);
  getDataOnChanges = new BehaviorSubject<boolean>(false);
  oNextDataDictionaryFolder = new BehaviorSubject<any>("");
  closeSidebar = new BehaviorSubject<any>("");
  showresponse = new BehaviorSubject<any>({ arg1: '', arg2: false });
  oDataSourceFolder = new BehaviorSubject<any>("");
  oMetaDataFolder = new BehaviorSubject<any>("");
  accessToken = localStorage.getItem(environment.AccessToken);
  UpdateDBandSchemaDescription = new BehaviorSubject<string>("");
  treeMenu=new BehaviorSubject<any>('');
  AddSchemaIntoDB = new BehaviorSubject<any>('');
  trash = new BehaviorSubject<boolean>(false);
  oNextFunctionTree = new BehaviorSubject<any>("");
  FunctionDetailsObject =  new BehaviorSubject<any>("");
  sendSelectedSchedule=new BehaviorSubject<any>("");
  AddTableIntoSchema=new BehaviorSubject<any>("");
  selectFolder = new BehaviorSubject<any>({ arg1: '', arg2: false });
  selectDocFolder = new BehaviorSubject<any>({ arg1: '', arg2: false });
  globalSearch = new BehaviorSubject<any>({ folderId:'', searchBoolean: false });
  SetApplicationOnRememberCheck=new BehaviorSubject<number>(14400);
  responseMessage=new BehaviorSubject<any>('');
  renameFolder = new BehaviorSubject<boolean>(false);
  renameDocFolder = new BehaviorSubject<boolean>(false);
  showdashBoardSearch =new BehaviorSubject<any>({ query:{}});
  constructor(private http: HttpClient) { }
  
    // Get Details according reports name 
    DataService_GetFilterRecords(body,id) : Observable<any>
    {
      return this.http.post<any>(environment.BaseURL + '/ds/pageByMatadataDataSource/'+id , body );
    }
    //Get Tables
    DataService_GetTablesName() : Observable<any>
    {
      return this.http.get<any>(environment.BaseURL + '/ds/md/listMetadata?modelClassNames=ClassM&visibility=dataManager' );
    }
    // Get Data Sources 
    DataService_GetDataSourceName() : Observable<any>
    {
      return this.http.get<any>(environment.BaseURL + '/ds/md/getClassesBySchema?visibility=dataManager' );
    }
    // Get Details according table name 
    DataService_GetDataTableAttributes(body) : Observable<any>
    {
      return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/ClassM/'+body.name+'?fileName='+body.fileName );
    }
    DataService_GetFileContent(modelClassName, body) : Observable<any>
    {
      return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/' + modelClassName + '/' +body.name+'?fileName='+body.fileName );
    }
    // Get table attributes 
    DataService_GetTableRecords(body,dsName: string,name, sSchemaName: string, sAppName: string) : Observable<any>
    {
      if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
      {
        if(dsName.includes("common"))
        {
          return this.http.post<any>(environment.BaseURL + '/ds/page/' + sAppName +"/" + dsName + "/"+ name, body );
        }
        else
        {
          return this.http.post<any>(environment.BaseURL + '/ds/page/' + sAppName +"/"+ dsName + '.' + name, body );
        }
      }
      else
      {
        return this.http.post<any>(environment.BaseURL + '/ds/page/' + sAppName +"/"+ dsName + "/" + name, body );
      }
    }
    // Sava data of ngx-datatable
    DataService_SaveEditRecords(sFunctionName,sAppName,dsName: string,name,sSchemaName,body) : Observable<any>
    {
      if(sFunctionName == 'saveAll')
      {
        if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
        {
          if(dsName.includes("common"))
          { //MySQL ==> appname/classname
            return this.http.post<any>(environment.BaseURL + '/ds/'+ sFunctionName + "/" + sAppName +"/" + dsName + "/"+ name, body );
          }
          else
          { //postgress / MicroSoft SQL ==> appname/schemaname.classname
            return this.http.post<any>(environment.BaseURL + '/ds/'+ sFunctionName + "/" + sAppName +"/"+ dsName + "/" + sSchemaName + '.' + name, body );
          }
        }
        else
        {
          return this.http.post<any>(environment.BaseURL + '/ds/'+ sFunctionName + "/" + sAppName +"/"+ dsName + "/" + name, body );
        }
      }
      else
      {
        return this.http.post<any>(environment.BaseURL + '/ds/function/' + sAppName +"/"+ sFunctionName, body );
      }
    }
    DataService_SaveColumnAttribute(body) : Observable<any>
    {
      return this.http.post<any>(environment.BaseURL + '/ds/md/saveMetadata', body );
    }
      // Get Distinct Values for auto suggestions 
  DataService_GetDistinctValuesForAutoSuggestion(dsName: string,name, sSchemaName: string, sAppName: string) : Observable<any>
  {
    // return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/'+ sAppName +"/" + dsName + "."+ name);
    if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
    {
      if(dsName.includes("common"))
      {
        return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/' + sAppName +"/" + dsName + "/"+ name );
      }
      else
      {
        return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/' + sAppName +"/"+ dsName + '.' + name );
      }
    }
    else
    {
      return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/' + sAppName +"/"+ dsName + "/" + name );
    }
  }
  DataService_DeleteRecord(sAppName,dsName: string,name,sSchemaName,body) : Observable<any>
  {
    if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
    {
      if(dsName.includes("common"))
      { //MySQL ==> appname/classname
        return this.http.delete<any>(environment.BaseURL + '/ds/delete/' + sAppName +"/" + dsName + "/"+ name + "?permanent=false&id=" + body );
      }
      else
      { //postgress / MicroSoft SQL ==> appname/schemaname.classname
        return this.http.delete<any>(environment.BaseURL + '/ds/delete/' + sAppName +"/"+ dsName + "/" + sSchemaName + '.' + name+ "?permanent=false&id=" + body );
      }
    }
    else
    {
      return this.http.delete<any>(environment.BaseURL + '/ds/delete/' + sAppName +"/"+ dsName + "/" + name+ "?permanent=false&id=" + body );
    }
  }
   //delete data table 
 DataService_DeleteDataTable(nId):Observable<any>
 {
   return this.http.delete<any>(environment.BaseURL +'/ds/md/deleteMetadata/'+nId)
 }
                                                                                                  // File Managers Functions Start //
  // user management api's
   //users list
  //  DataService_GetUsersList(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body);
  // }
  //  //upload  users
  //  DataService_UploadUsers(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/authds/uploadUsers', body);

  // }
  //  //create user
  //  DataService_CreateUser(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body);
  // }
  //  //edit user
  //  DataService_EditUser(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
  // }
  DataService_EditUserProfile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editMyProfile', body);
  }
    //reset user password
    // DataService_ResetUserPassword(body): Observable<any> {
    //   return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body);
    // }
      //suspend user
  //  DataService_SuspendUser(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body);
  // }
  //  //delete user
  //  DataService_DeleteUser(id : any): Observable<any> {
  //   return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser' + "?id="+ id);
  // }
  // //roles list
  DataService_GetRolesList(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/_service/um/getRoles');
  }
  // // new user role
  // DataService_GetNewUserRole(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/_service/um/saveRole', body);
  // }
  //   // edit  role
  //   DataService_GetEditRole(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/_service/um/saveRole', body);
  // }
   //forgot password request
   DataService_ForgotPassword(body): Observable<any>
   {
     return this.http.post<any>(environment.BaseURL+'/_preauthsvc/user/forgotPassword',body)
   }
    //Request Verification Link
    DataService_VerificationLink(body): Observable<any>
    {
      return this.http.post<any>(environment.BaseURL+'/_preauthsvc/user/resendVerifyEmail',body)
    }
   //reset password
   DataService_ResetPassword(body): Observable<any> {
     return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body);
   }
    // get folders
  cDataService_GetFolders(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/folders');
  }

  //get folder and files
  cDataService_GetFoldersAndFiles(body: any): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/folderAndFiles?folderId=' + body.id + '&deleted=' + body.deleted + '&orderBy=' + body.orderBy);
  }
  //get sub folders 
  cDataService_GetSubFolders(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/subFolders/'+'?folderId='+body.folderId);
  }
  //autocomplete 
  cDataService_SearchAutoComplete(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/autoComplete', body);
  }
  //overall serach
  cDataService_Search(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/search', body);
  }
  DataService_GetSearchAutoComplete(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/autoComplete', body);
  }
  DataService_RetrieveSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/retieveSignedUrl', body);
  }
  DataService_RenameFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/rename', body);
  }
  DataService_RenameFolder(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/rename', body);
  }
  
  DataService_DeleetFolders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/delete', body);
  }
  DataService_PasteFileandFolders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/move', body);
  }
  DataService_CopyFiles(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/copy', body);
  }
  DataService_DeleteFiles(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/deleteMany', body);
  }
  DataService_NewFolder(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/create', body);
  }
  DataService_CreateDownloadSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/createDownloadSignedUrl', body);
  }
  DataService_CreateUploadSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/createUploadSignedUrl', body);
  }
  DataService_isBusy(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/isBusy');
  }
  DataService_RestoreFromTrash(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/restoreMany', body);
  }
  DataService_GetUsersGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GroupList', body);
  }
  DataService_GetUsersGroupMembers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GroupDetail', body);
  }
  DataService_AddNewGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/newGroup', body);
  }
  DataService_DeleteGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/group/delete', body);
  }
  DataService_AddMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/addMember', body);
  }
  DataService_DeleteMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/deleteMember', body);
  }
  DataService_EditMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/updateMember', body)
  }
  DataService_GetFMUsers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetFMUsers', body)
  }
  DataService_AssignTypist(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/share', body)
  }	
  DataService_CreateReferenceFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/CreateReferenceFile', body)
  }
  DataService_GetFileDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetFileDetails', body)
  }
  DataServiceGetTreeForDataSource(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/metaDataTree?modelClassName=ClassM', {})
  }
  DataServiceGetTreeForDataSourceWithSystemCheck(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/metaDataTree?modelClassName=ClassM&option=includeSystem', {})
  }
  DataServiceGetTreeForFunctionWithSystemCheck(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/metaDataTree?modelClassName=FunctionM&option=includeSystem', {})
  }
  DataService_UpdateDataSourceAndSchemaDescription(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/md/saveMetadata', body)
  }
  DataService_GetSchemaandDatabaseDescription(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/DataSourceM/'+fileName)
  }
  DataService_GetClassAPIs(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getAPIs/ClassM/'+fileName)
  }
  DataService_GetSchemaDescription(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/SchemaM/'+fileName)
  }
  DataService_GetFunctionDetails(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/FunctionM/'+fileName)
  }
  // get Table description
  DataService_GetTableDescription(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/ClassM/'+fileName)
  }
  DataServiceGetTreeForDataDictionary(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/metaDataTree?modelClassName=ClassM&dataDictionaryMode=true', {})
  }
  DataServiceGetTreeForFunction(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/metaDataTree?modelClassName=FunctionM&', {})
  }
   // Task Details 
   DataService_GetTaskDetails(body): Observable<any> 
   {
     return this.http.post<any>(environment.BaseURL + '/ds/page/SysTaskExec', body);
   }
   // Tasks Cancel
   DataService_CancelTasks(body): Observable<any> 
   {
     return this.http.post<any>(environment.BaseURL + '/_service/systask/cancelTasks', body);
   }
   DataService_UpdateFileStatus(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/updateStatus', body)
  }
  DataService_GetKeyValuesByType(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/getKeyValuesByType?type='+body);
  }
  DataService_SavekeyValue(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/saveKeyValue', body)
  }
  DataService_GetPublicKeys(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/PublicKey?',body);
  }
  DataService_AddNewPublicKey(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/newPublicKey?',body);
  }
  DataService_DeletePublicKey(body): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/ds/delete/shared/common/PublicKey?'+body);
  }
  DataService_GetApiCredential(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/dsmodel/getApiCredentials?')
  }
  DataService_CreateCredential(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/dsmodel/createCredential', body)
  }
  DataService_DeleteCredential(body): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/dsmodel/deleteCredential?id='+body)
  }
  DataService_NewClientSecret(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/dsmodel/newClientSecret',body)
  }
  DataService_SaveClientSecret(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/dsmodel/saveCredential?',body)
  }
  DataService_SaveLogo(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/saveLogo',body)
  }
  DataService_GetLogo(): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/preauth_function/shared/GetLogo',{})
  }
  DataService_GetLoginConfigLogo(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/preauth_function/shared/GetLoginConfig')
  }
  DataService_GetQueriestableList(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/listMetadata?modelClassNames=QueryM')
  }
  DataService_AddNewQyerryTable(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/createMetadata',body)
  }
  DataService_GetSelectedQuerieData(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/getMetadata/'+body)
  }
  DataService_TestQuerry(type,tablename,body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/'+type+'/'+tablename,body)
  }
  DataService_QuerrySaveMetaData(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/saveMetadata',body)
  }
  DataService_SaveSchemaInDataBase(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/createMetadata',body)
  }
  DataService_CreateSchemaFromSelectedSchema(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/createSchema?',body)
  }
  DataService_UpdateSchemaInDataDictionary(IncomingParams,body): Observable<any> {
    return this.http.put<any>(environment.BaseURL +'/ds/md/updateSchema/'+IncomingParams,body)
  }
  DataService_GetPreviousVersin(body,version): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/getMetadata/'+body+'?priorVersionOffset='+version)
  }
  DataService_RenameQuerry(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/renameMetadata',body)
  }
  DataService_DeleteTable(body): Observable<any> {
    return this.http.delete<any>(environment.BaseURL +'/ds/md/deleteMetadata/'+body)
  }


  DataService_GetWorkFlows(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/listMetadata?modelClassNames=FlowM')
  }
  DataService_GetSelectedWorkFlowMetadata(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/getMetadata/'+body)
  }
  DataService_CreateWorkFlow(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/createMetadata',body)
  }
  DataService_DeleteWorkFlow(body): Observable<any> {
    return this.http.delete<any>(environment.BaseURL +'/ds/md/deleteMetadata/'+body)
  }
  DataService_RenameWorkFlow(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/renameMetadata',body)
  }
  DataService_SaveWorkFlow(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/saveMetadata',body)
  }
  DataService_TestConnection(connection): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/dsmodel/test/'+connection)
  }
  DataService_ShowTables(path,body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/dsmodel/allClasses/'+path+'?includeAttrs='+body)
  }
  DataService_Introspect(path,body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/dsmodel/introspect/'+path+'?includeAttrs='+body)
  }
  DataService_SaveIntrospectTableaMetadata(path,body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/saveDSClasses/'+path,body)
  }
  DataService_CacheView(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/cacheAllViews',body)
  }
  DataService_CreateDb(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/createDB',body)
  }
  DataService_UpdateDb(path,body): Observable<any> {
    return this.http.put<any>(environment.BaseURL +'/ds/md/updateSchema/'+path,body)
  }
  DataService_DescribeTableFromDb(path): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/describeTable/'+path)
  }
  DataService_CodeCompilation(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/compilefunction',body)
  }
  DataService_FunctionSharedApis(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/functionApis/shared/'+body)
  }
  DataService_ExecuteFunction(name: string, method: any, body: any): Observable<any> {
    let request;
    const url = environment.BaseURL + '/ds/function/shared/' + name +'/'+ method.methodName;
    // Check the method and create the appropriate request
    console.log('HTTP Method:', method);

    switch (method.httpMethod) {
      case 'GET':
        let updatedUrl = url;
        if (body) {
          updatedUrl += `?${body.toString()}`;
        }
        request = this.http.get<any>(updatedUrl);
        break;
      case 'POST':
        request = this.http.post<any>(url, body);
        break;
      case 'PUT':
        request = this.http.put<any>(url, body);
        break;
      case 'DELETE':
        request = this.http.delete<any>(url);
        break;
      // Handle other HTTP methods as needed
      default:
        throw new Error('Unsupported HTTP method');
    }

    return request;
  }
  DataService_ExecuteFunctiononBackGround(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/function/createFunctionSysTask2',body)
  }

  DataService_GetMetadataFolders(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/folders/')
  }
  DataService_GetMetadataFiles(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/files?folderId='+body)
  }


  // Methods For Data Source API component 
  DataService_DataSourceAPIGet(endPoint): Observable<any> {
    return this.http.get<any>(environment.BaseURL + endPoint)
  }
  DataService_DataSourceAPIPost(endPoint,body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + endPoint,body)
  }
  DataService_DataSourceAPIDelete(endPoint,body): Observable<any> {
    return this.http.delete<any>(environment.BaseURL +endPoint,body)
  }
  //schedules Api
  DataService_GetScheduleList(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/listMetadata?modelClassNames=ScheduleM')
  }
  DataService_StartSchedule(endPoint,body): Observable<any> {
    return this.http.put<any>(environment.BaseURL +'/ds/md/'+endPoint,body)
  }
  DataService_ChangePassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/_service/um/changeMyPassword',body)
  }
  DataService_GetGitdetails(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/_service/rtgit/gitDetail')
  }
  DataService_AddRepository(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/rtgit/saveClientInfo',body)
  }
  DataService_GetGitRepoUrl(orgName): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/_service/rtgit/gitRepoUrl?orgName='+orgName)
  }
  DataService_GitPush(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/md/rtgit/pushToGit',body)
  }
  DataService_GitTask(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/ds/md/rtgit/gitTask')
  }
  DataService_GetConfigFIle(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/_preauthsvc/appConfig')
  }
  DataService_FolderSync(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/foldersvc/cloudstorage/folder/sync',body)
  }
  //get Search Results For Data Dictionary
  cDataService_GetSearchResultForDataDictionary(searchPhrase): Observable<any> {
  return this.http.get<any>(environment.BaseURL + '/ds/md/search?searchPhrase='+searchPhrase);
  }
  // Tenant Information
  DataServiceGetTenantInformation(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/_service/um/currentUserTenantOrg', {})
  }
  // Folder Config
  DataService_GetFolderConfig(IncomingId): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/getKeyValue?key=Folder.Config.'+IncomingId+'&type=config')
  }
  DataService_SaveFolderConfig(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/saveKeyValue',body)
  }
  DataService_ShareFolderFile(body,IncomingType): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/'+IncomingType+'/share',body)
  }

  // DocService

  // get folders
  cDataService_GetDocFolders(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/docsvc/folders');
  }

  //get folder and files
  cDataService_GetDocFoldersAndDocs(body: any): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/docsvc/foldersAndDocs?folderId=' + body.id + '&deleted=' + body.deleted + '&orderBy=' + body.orderBy);
  }
  //get sub folders 
  cDataService_GetDocSubFolders(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/docSvc/subFolders/'+'?folderId='+body.folderId);
  }
  //autocomplete 
  cDataService_DocSearchAutoComplete(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/autoComplete', body);
  }
  //overall serach
  cDataService_DocSearch(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/search', body);
  }
  DataService_GetDocSearchAutoComplete(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/autoComplete', body);
  }
  DataService_DocRetrieveSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/folder/retrieveSignedUrl', body);
  }
  DataService_RenameDoc(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/doc/rename', body);
  }
  DataService_RenameDocFolder(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/folder/rename', body);
  }
  
  DataService_DeleetDocFolders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/folder/delete', body);
  }
  DataService_PasteDocandFolders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/doc/move', body);
  }
  DataService_CopyDocs(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/doc/copy', body);
  }
  DataService_DeleteDocs(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/doc/deleteMany', body);
  }
  DataService_NewDocFolder(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/folder/create', body);
  }
  DataService_CreateDocDownloadSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/folder/createDownloadSignedUrl', body);
  }
  DataService_RestoreDocsFromTrash(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/cloudstorage/doc/restoreMany', body);
  }
  // For shared file Link
  DataService_GetSignedAction(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/_service/public/signedAction?secret='+body)
  }
  DataService_RetrieveURLforSharedLinkFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/public/retieveSignedUrlPublic', body);
  }
  DataService_RetrieveURLforUploadLinkFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/public/retieveSignedUrlPublic', body);
  }
  DataService_SaveUploadFileObjectPublic(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/public/saveFileObjectPublic', body);
  }
  
  // Extract Data From Files
  DataService_ExtractDataFromFiles(body): Observable<any> {
  return this.http.get<any>(environment.BaseURL +'/docsvc/docAIResults?folderId='+body)
  }
  DataService_ExecuteWorkflowForSelectedFolder(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/executeWorkflow', body);
  }

  // ***** Get Global Search Results For Document Manager ***** //
  DataService_GetGlobalSearchResultsForDocumentManager(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/docsvc/search', body);
  }

  // ***** Get Summary Results For Document Manager ***** //
  DataService_SummaryFilesFromFolder(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/docsvc/summaries?folderId='+body)
  }
  // Extract Data From Global Search Files
  DataService_ExtractDataFromGlobalSearchFiles(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/docsvc/docAIResult?docId='+body)
  }
  //Get File versions
  DataService_GetFilesVersions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/page/File',body)
  }
  DataService_GetWBVersion(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/_rt/appVersion')
  }
  DataService_UpdateDocFolderProperties(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/docsvc/gcs/folder/updateDocFolderProperties',body)
  }
  DataService_UpdateDocProperties(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/docsvc/gcs/doc/updateDocProperties',body)
  }
  DataService_SearchUsers(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/lookup/query/auth.vault.User/userId?partialPhrase='+body)
  }
  DataService_SearchGroups(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/lookup/query/shared.common.Group/name?partialPhrase='+body)
  }
  DataService_GetAccessRuleByResource(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/accesscontrol/getAccessControlRuleByResource/Folder/'+body)
  }
  DataService_SaveAccessControlRule(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/accesscontrol/saveAccessControlRule',body)
  }
  DataService_GetDashboardData(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +'/lookup/dashboard/DocAI/')
  }
  DataService_SaveBulkUpload(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/docsvc/bulkUpload',body)
  }
   //Services Authorization api
   DataService_ServicesAuthorizationName(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/page/auth/vault?_',body)
  }
  DataService_ServicesAuthorizationNewService(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/save/auth/vault/SvcAuthorization?_=1698907408139',body)
  }
  DataService_ServicesAuthorizationDeleteService(body): Observable<any> {
    return this.http.delete<any>(environment.BaseURL +"/ds/delete/auth/vault/SvcAuthorization"+ "?id=" +body)
  }
  DataService_ServicesAuthorizationGetService(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +"/_oauth2/svcAuthorization"+ "?id=" +body)
  }
  DataService_ServicesAuthorizationSaveService(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +"/ds/save/auth/vault/SvcAuthorization?" ,body)
  }
  DataService_GetAPIPlayGroundList(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +"/ds/md/listApis", body)
  }
  DataService_GetSelectedAPIplaygroundData(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL +"/ds/md/getWBApis/"+body)
  }
  DataService_ImportMetadata(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +"/ds/md/importMetadata",body)
  }
  DataService__gitTask(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +"/ds/md/rtgit/gitTask")
  }
  DataService__getDocumentAIProcessors(): Observable<any> {
    return this.http.get<any>(environment.BaseURL +"/docsvc/listEnabledDocumentAIProcessors")
  }

}

